@import "~@/erp/styles/variables/variables.scss";


































































































































































































.footer {
  .el-button + .el-button {
    margin-left: 30px;
  }
}
.loc-item {
  display: flex;
  align-items: center;
}

.error-p {
  position: absolute;
  left: 249px;
  top: 40px;
  color: #ff4d4f;
  font-size: 12px;
}
::v-deep {
  .loc-wrapper .el-input-number--small {
    width: 100px !important;
  }

  .el-input-number--small .el-input__inner {
    padding: 0 10px !important;
  }
  .el-input-number--small .el-input-number__increase,
  .el-input-number--small .el-input-number__decrease {
    display: none !important;
  }
}
