@import "~@/erp/styles/variables/variables.scss";


















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-form {
  padding: 0 10px;
  .lot-wrapper {
    display: flex;
    align-items: center;
    .el-input {
      flex: 1;
    }
    .el-icon-circle-plus-outline {
      margin-left: 5px;
      cursor: pointer;
      font-size: 24px;
      color: #409eff;
    }
  }
}
.oper-btn {
  position: sticky;
  bottom: 0;
  text-align: center;
  background: white;
  padding: 10px 0;
}
::v-deep {
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  .goodsInfo {
    margin: 0;
    padding: 0;
    > .cell {
      padding: 0;
      .el-table__body-wrapper {
        overflow: hidden;
      }
    }
  }
}
