/*! 2025-2-14 17:56:46 */
[data-v-4287d15e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.pagination-container[data-v-4287d15e]{background-color:#fff;padding:20px 10px;text-align:right;padding-right:60px}.pagination-container.hidden[data-v-4287d15e]{display:none}.pagination-container.left[data-v-4287d15e]{text-align:left}

[data-v-21691458]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.footer .el-button+.el-button[data-v-21691458]{margin-left:30px}.ml15[data-v-21691458]{margin-left:15px}.img-wrap[data-v-21691458]{position:relative;padding:3px 0;width:50px;margin:0 auto}.img-wrap .el-image[data-v-21691458]{width:50px;height:50px;-o-object-fit:cover;object-fit:cover;display:block}.img-wrap .el-badge[data-v-21691458]{position:absolute;top:3px;right:-10px;z-index:2}[data-v-21691458] .el-dialog__body{padding:0 20px 20px}

[data-v-fd2922ae]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.multi[data-v-fd2922ae]{display:flex;justify-content:space-between}.multi>span[data-v-fd2922ae]{width:50%;text-align:left;display:block}

[data-v-750751bd]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.sum[data-v-750751bd]{margin-top:10px;text-align:right}.sum span[data-v-750751bd]{margin-right:1px}.sum span.sum1[data-v-750751bd]{margin-right:15px}.sum span.sum2[data-v-750751bd]{margin-right:20px}.bom[data-v-750751bd]{width:100%;height:100%;padding-bottom:60px;position:relative}.bom .L[data-v-750751bd]{width:58%;padding:0 10px 0 20px}.bom .M[data-v-750751bd]{display:flex;align-items:center}.bom .R[data-v-750751bd]{width:calc(42% - 50px);padding:0 20px}.bom .btns[data-v-750751bd]{position:absolute;bottom:0;left:0;width:100%;height:60px;text-align:right;padding:14px 20px;border-top:1px solid #ededed}.bom .el-tabs .el-button[data-v-750751bd]{height:34px !important}.bom .el-tabs .el-table[data-v-750751bd]{height:calc(100vh - 220px);overflow-y:auto}.bom .el-tabs .el-pagination[data-v-750751bd]{height:40px}.bom .el-form[data-v-750751bd]{height:calc(100vh - 149px);overflow-y:auto}.bom .img-wrap[data-v-750751bd]{position:relative;padding:3px 0;width:50px;margin:0 auto}.bom .img-wrap .el-image[data-v-750751bd]{width:50px;height:50px;-o-object-fit:cover;object-fit:cover;display:block}.bom .img-wrap .el-badge[data-v-750751bd]{position:absolute;top:3px;right:-10px;z-index:2}

[data-v-071cddc8]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.footer .el-button+.el-button[data-v-071cddc8]{margin-left:30px}.loc-item[data-v-071cddc8]{display:flex;align-items:center}.error-p[data-v-071cddc8]{position:absolute;left:249px;top:40px;color:#ff4d4f;font-size:12px}[data-v-071cddc8] .loc-wrapper .el-input-number--small{width:100px !important}[data-v-071cddc8] .el-input-number--small .el-input__inner{padding:0 10px !important}[data-v-071cddc8] .el-input-number--small .el-input-number__increase,[data-v-071cddc8] .el-input-number--small .el-input-number__decrease{display:none !important}

[data-v-b1fd2de8]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.el-form[data-v-b1fd2de8]{padding:0 10px}.el-form .lot-wrapper[data-v-b1fd2de8]{display:flex;align-items:center}.el-form .lot-wrapper .el-input[data-v-b1fd2de8]{flex:1}.el-form .lot-wrapper .el-icon-circle-plus-outline[data-v-b1fd2de8]{margin-left:5px;cursor:pointer;font-size:24px;color:#409eff}.oper-btn[data-v-b1fd2de8]{position:sticky;bottom:0;text-align:center;background:white;padding:10px 0}[data-v-b1fd2de8] .el-select,[data-v-b1fd2de8] .el-date-editor{width:100%}[data-v-b1fd2de8] .goodsInfo{margin:0;padding:0}[data-v-b1fd2de8] .goodsInfo>.cell{padding:0}[data-v-b1fd2de8] .goodsInfo>.cell .el-table__body-wrapper{overflow:hidden}


/*# sourceMappingURL=chunk-0b2cd6ea.cc1a497a.css.map*/