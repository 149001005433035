@import "~@/erp/styles/variables/variables.scss";














































































































.multi {
  display: flex;
  justify-content: space-between;
  > span {
    width: 50%;
    text-align: left;
    display: block;
  }
}
